<template>
  <v-container fluid>
    <page-title title="Календарь Голубого муравьеда">
      <template #description>
        <v-card-text
          style="color: black; white-space: pre-line"
          :class="{ 'body-2': $vuetify.breakpoint.xs }"
          class=" headline text-justify body-1 "
          ><span
            >Для того, чтобы раскрыть и проработать актуальную тему, не всегда нужна терапия или
            серьезные задания. В каждом календаре Голубого муравьеда вы найдете набор простых
            игровых упражнений, которые помогут вам по-новому взглянуть на проблему. Выбирайте
            тему!</span
          ></v-card-text
        >
      </template>
    </page-title>
    <!--    <select-author :list="filterSelect" />-->
    <list-component :list="modifiedListCalendars" @createPath="createPath" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CalendarList',
  metaInfo: {
    title: 'Календарь Голубого муравьеда | MetaMorfosi',
    titleTemplate: null
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle')
    //   SelectAuthor: () => import('@/components/shared/SelectAuthor')
  },
  computed: {
    ...mapState({
      listCalendars: state => state.studios.listCalendars,
      loading: state => state.shared.loading,
      user: state => state.user.user,
      listAuthors: state => state.shared.listAuthors
    }),
    ...mapGetters(['filterListCalendars', 'filterAllListCalendars']),
    filterSelect() {
      return this.filterAllListCalendars.filter(calendar => !calendar.checkNeurographics)
    },
    modifiedListCalendars() {
      return this.filterListCalendars
        .filter(
          calendar => calendar.secondType !== 'artTherapy' && calendar.secondType !== 'spaceDrawing'
        )
        .map(studio => {
          const dadaAuthor = this.listAuthors.find(author => author.id === studio.author)
          return {
            ...studio,
            authorName: `${dadaAuthor.firstName} ${dadaAuthor.lastName}`
          }
        })
    }
  },
  created() {
    if (this.listCalendars.length === 0) this.$store.dispatch('getListStudios', 'calendars')
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  methods: {
    createPath(value) {
      if (this.user.payments && this.user.payments[value]) {
        this.$router.push(`/calendar/${value}`)
      } else {
        this.$router.push(`/calendar/preview/${value}`)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
